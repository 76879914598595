footer {
  .footer__social-icons {
    a {
      height: 48px;
      width: 48px;
      line-height: 3.2;
      border-radius: 50%;
      background: $secondary;
      display: inline-block;
      text-align: center;

      &:hover {
        background: $genoa;
      }
    }
  }
}
