.section-title {
  h2 {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      border-top: 5px solid $primary;
      border-radius: 5px;
      width: 8%;
      top: -20px;
      left: 46%;
    }
  }
  .section-title-left{
    &:before{
      @include media-breakpoint-up(md) {
        left:0;
        width: 15%;
      }
    }
  } 
}

@mixin font-light {
  font-weight: 300 !important;
}

@mixin font-regular {
  font-weight: 400 !important;
}

@mixin font-semi-bold {
  font-weight: 600 !important;
}

@mixin font-bold {
  font-weight: 700 !important;
}

@mixin text-clear {
  color: $gray-600 !important;
}

.font-light {
  @include font-light;
}

.font-regular{
  @include font-regular;
}

.font-semi-bold {
  @include font-semi-bold;
}

.font-bold {
  @include font-bold;
}
