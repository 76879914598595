.contact__form {
  .input-group {
    $form-input-radius: 20px;

    .form-control:first-child {
      border-top-left-radius: $form-input-radius;
      border-bottom-left-radius: $form-input-radius;
    }

    .input-group-append > .btn {
      border-top-right-radius: $form-input-radius;
      border-bottom-right-radius: $form-input-radius;
    }
  }

  .form-response-container {
    height: 10px;
  }

  .toast-container {
    .toast {
      display: none;
      z-index: 99999 !important;

      .toast-header {
        background-color: $genoa;
      }
    }
  }
}
