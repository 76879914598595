nav {
  ul {
    @include media-breakpoint-between(xs, md) {
      padding-bottom: 100vh;
      background-color: $genoa;

      .nav-item {
        position: relative;
        margin-top: 4px;
        margin-bottom: 4px;

        &:first-child {
          margin-top: 10px;
        }

        &:after {
          display: block;
          position: absolute;
          content: '';
          top: 8px;
          right: 1rem;
          background-image: url('../icons/arrow-down-white.svg');
          width: 16px;
          height: 16px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
          transform: rotate(-90deg);

          @include media-breakpoint-up(sm) {
            right: 3rem;
          }
        }
      }
    }
  }

  .btn-epayco {
    background-color: transparent;
    color:#fff;
    outline: none;
    border: 0;
    &:hover {
      color:#000;
    }
    &:focus {
      outline: none;
    }
  }
}
