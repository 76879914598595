.services {
  background: $polar;

  .service-card {
    $border-radius: 70px;
    border-top-right-radius: $border-radius;

    &__image {
      height: 28vh;
      width: 100%;
      object-fit: cover;
      border-radius: 0 $border-radius 0 $border-radius;
    }

    p {
      line-height: 1.3;
    }

    h6 {
      margin-bottom: 0.75rem;
    }

    h5 {
      margin-bottom: 0.25rem;
    }

    @include media-breakpoint-up(md) {
      $image-width: 32%;
      position: relative;
      border-radius: 0 0 0 $border-radius;

      &__image {
        position: absolute;
        width: $image-width;
        height: 100%;
        left: 0;
        z-index: 10;
      }

      &__content {
        margin-left: $image-width;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
