#header {
  $images-horizontal-distance: 340px;

  height: $header-height;

  .header__backdrop {
    $initial_color: rgba($genoa, 0.8);
    $final_color: rgba($primary, 0.8);

    @include gradient-background($initial_color, $final_color);
  }

  .header__background-image {
    top: -40px;
    left: -80px;

    @include media-breakpoint-up(lg) {
      left: calc(45% - 1.5 * #{$images-horizontal-distance});
    }
  }

  .header__overlay-image {
    visibility: hidden;
    bottom: 0;
    left: calc(45% + #{$images-horizontal-distance} * (1 - 1.5));

    @include media-breakpoint-up(lg) {
      visibility: visible;
    }
  }

  .app-buttons {
    filter: brightness(0) invert(0.9) drop-shadow(0px 0px 15px $primary);
  }

  .header__contact {
    margin-top: 230px;

    @include media-breakpoint-up(sm) {
      width: calc(368px + 6rem) !important;
    }
  }
}
