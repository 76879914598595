.landing-view {

/** ABOUT */

/** BENEFITS */

/** SERVICES */

/** CLIENTS */

/** ALIMENTS */

/** FEATURED CLIENTS */

/** APP */
.logo-environment {
  @include media-breakpoint-up(md) {
    position: absolute;
  }
}

/** SPONSORS */

/** CTA */
.cta {
  height: 280px;

  .cta__backdrop {
    $initial_color: rgba($genoa, 0.8);
    $final_color: rgba($primary, 0.8);

    @include gradient-background($initial_color, $final_color);
  }

  .cta__background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      left: 0;
    }
  }
}

}
