// Bootstrap and other libraries should be imported in vendor.scss in order to create a different chunk for caching purposes
// If we import bootstrap here as well we would have duplicated css increasing the page loading time,
// however, the variables scss is shared between the app and the libraries, therefore, you can use it to override variables like:
// bootstrap $primary, $secondary, $grid-gutter-width, etc.
@import "variables";

// Imports required to use bootstrap variables that are not overridden by _variables.scss
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Project scss
@import "utilities";
@import "components";
@import "views";
@import "typography";

html, body {
  height: 100%;
  font-size: 16px;
}

.whatsapp-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: $whatsapp;
  transition: transform 0.5s;
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
}
